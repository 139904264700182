import React from "react";
import Heading from "src/components/Heading";
import { Link, graphql, useStaticQuery } from "gatsby";
import RichText from "src/utils/RichText";
import FluidImage from "src/utils/FluidImage";
import GetDate from "./GetDate";
import BlogArchiveFilters from "./BlogArchiveFilters";
import resolveLink from "src/utils/resolveLink";

const BlogArchive = ({ posts, numPages, currentPage, tag, tagSlug }) => {
  const { settings } = useStaticQuery(graphql`
    query BlogSettings {
      settings: allStoryblokEntry(
        filter: { field_component: { eq: "settings" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
    }
  `);
  let content = JSON.parse(settings.edges[0].node.content);
  let parsedSetting = Object.assign({}, content, { content: content });

  return (
    <>
      <section className="py-12 lg:py-16">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <Heading size="h1" className="text-red">
            News &amp; Insights {tag && `- ${tag}`}
          </Heading>
          <div className="prose font-body max-w-prose">
            <RichText>{parsedSetting.news_and_insights_description}</RichText>
          </div>
        </div>
      </section>
      <section className="bg-gray-100 py-12 lg:py-16">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <BlogArchiveFilters />
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
            {posts.map((item, index) => {
              const blok = JSON.parse(item.node.content);

              return (
                <Link
                  to={resolveLink(`/${item.node.full_slug}`)}
                  key={index}
                  className="group"
                >
                  <FluidImage
                    className="w-full rounded transition duration-500 ease-in-out group-hover:opacity-80"
                    image={blok.image}
                  />
                  <GetDate
                    date={item.node.first_published_at}
                    className="mt-5"
                  />
                  <Heading size="h4" className="text-blue mt-3 mb-1">
                    {blok.name}
                  </Heading>
                  <div className="font-body text-blue prose leading-6">
                    <RichText>{blok.description}</RichText>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </section>
      {numPages > 1 && (
        <nav aria-label="Page navigation" className="py-12">
          <ul className="space-x-2 text-center">
            {Array.from({ length: numPages }, (_, i) => (
              <li className="inline-block" key={i}>
                <Link
                  className={`flex items-center justify-center ${
                    currentPage === i + 1 ? "text-red" : "text-blue-500"
                  } font-display h-6 w-6 text-base`}
                  key={`pagination-number${i + 1}`}
                  to={`/blog/${tagSlug ? `category/${tagSlug}/` : ""}${
                    i === 0 ? "" : `${i + 1}/`
                  }`}
                >
                  <span>{i + 1}</span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </>
  );
};

export default BlogArchive;
